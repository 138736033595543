import React from "react";
import PropTypes from "prop-types";
import "./ErrorText.css";

const ErrorText = ({ children, ...props }) => {
  return (
    <span {...props} className="error-text">
      {children}
    </span>
  );
};
ErrorText.propTypes = {
  children: PropTypes.node.isRequired,
  props: PropTypes.object,
};

export default ErrorText;
