import { useEffect, useState } from "react";

const useWebSocket = (url) => {
  const [broadcastMessage, setBroadcastMessage] = useState(null);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const ws = new WebSocket(url);
    setSocket(ws);

    ws.onopen = () => {
      console.log("WebSocket connected");
    };

    ws.onmessage = (event) => {
      console.log("WebSocket message received:", event.data);
      setBroadcastMessage(event.data);
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };

    ws.onclose = () => {
      console.log("WebSocket disconnected");
    };

    return () => ws.close(); // Cleanup on component unmount
  }, [url]);

  const sendMessageWebSocket = (msg) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      console.log(`Sending message ChatBot: ${msg}`); // Log the message being sent
      socket.send(msg);
    } else {
      console.warn("WebSocket is not open. Unable to send message."); // Log a warning if the WebSocket is not ready
    }
  };

  return { broadcastMessage, sendMessageWebSocket };
};

export default useWebSocket;
