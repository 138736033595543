import React from "react";
import "./VersionList.css";

// Sample data with environments
const versions = [
  {
    version: "0.0.1" ,
    live:  true,
    environment: <div className="version-badge" id="staging">Staging</div>,
    features: [
      "Feature 1: Only available in staging",
      "Feature 2: Testing new features",
    ],
  },
 
  {
    version: "1.0",
    live:  false,
    environment: <div className="version-badge" id="production">Production</div>,
    features: [
      "Feature 1: Released to production",
      "Feature 2: Stable features",
    ],
  },
];

const VersionList = () => {
  return (
    <div className="versions-container">
      <h1>List of Versions & Features</h1>
      <div className="versions-card-holder">
      {versions.map((version, index) => (
        <div className="version-card" 
          key={index}
          id={`v${version.version}`}
          current={version.current}>
         <div className="version-card-title-badge"> <h2>
            Version {version.version}
          </h2><div>
              {version.live ? (
                <div className="current-badge">Current</div>
              ) : (
                <div className="coming-soon">Coming Soon</div>
              )}
            </div></div>
          
          <p>
            <strong>Environment:</strong> {version.environment}
          </p>
          
          <ul >
            {version.features.map((feature, featureIndex) => (
              <li
                key={featureIndex} >
                {feature}
              </li>
            ))}
          </ul>
        </div>
      ))}</div>
    
   
    <div className="date">
      <p>
        Last Updated: {new Date().toLocaleDateString()}</p>
      </div>

   
   

    
    </div>
  );
};

export default VersionList;
