import React, { useState } from "react";
import NewChatbot from "../components/NewChatbot.js";
import useAuth from "../hook/useAuth.js";
import "./NewMainChatbot.css";

const NewMainChatbot = () => {
  const { logged, login, logout } = useAuth();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const handleLogin = () => {
    if (!login(username, password)) {
      alert("Invalid credentials");
    }
  };

  return (
    <div style={styles.container}>
      {isOpen && <div className="overlay"></div>}
      <h1 style={styles.title}>Chatbot - v 0.1.0</h1>
      <p style={styles.message}>
        Please login to test chat, username is case sensitive
      </p>
      {logged ? (
        <>
          <NewChatbot setIsChatOpen={setIsOpen} />
          <button onClick={logout}>Logout</button>
        </>
      ) : (
        <div className="login-container">
          <h2>Login</h2>
          <input
            type="text"
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button onClick={handleLogin}>Login</button>
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    minHeight: "9000px",
    backgroundColor: "#212121",
  },
  title: {
    fontSize: "48px",
    color: "#333",
  },
  message: {
    fontSize: "18px",
    color: "#666",
  },
  link: {
    marginTop: "20px",
    display: "inline-block",
    fontSize: "16px",
    color: "#007bff",
    textDecoration: "underline",
  },
};

export default NewMainChatbot;
