import PropTypes from 'prop-types';
import React from "react";
import "./EndChatPrompt.css";
import {ReactComponent as IconEndConversation} from "../assets/iconEndConversation.svg";

const EndChatPrompt = ({ onConfirmEnd, onCancelEnd }) => {
  return (
    <div className="end-chat-overlay">
      <div className="end-chat-box">
        <IconEndConversation className="end-chat-icon" />

        <h2 className="end-chat-title">End Chat?</h2>
        <p className="end-chat-message">
          Are you sure you want to finish this chat?
        </p>
        <div className="end-chat-buttons">
          <button className="end-chat-button confirm" onClick={onConfirmEnd}>
            Yes, End Chat
          </button>
          <button className="end-chat-button cancel" onClick={onCancelEnd}>
            No, Continue Chat
          </button>
        </div>
      </div>
    </div>
  );
};

EndChatPrompt.propTypes = {
  onConfirmEnd: PropTypes.func.isRequired,
  onCancelEnd: PropTypes.func.isRequired,
};

export default EndChatPrompt;