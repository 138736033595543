import { AnimatePresence, motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { HiOutlineArrowsExpand, HiOutlineLogout, HiOutlineX } from "react-icons/hi";
import { v4 as uuidv4 } from "uuid";
import useApiRequest from "../hook/useApiRequest.js";
import useAuth from "../hook/useAuth.js";
import useWebSocket from "../hook/useWebSocket.js";
import MessageWebsocket from "../models/MessageWebsocket.js";
import { SENDER_BOT, SENDER_USER, STATUS_DISCONNECTED, WEBSOCKET_SERVER } from "../utils/constants.js";
import { generateTimestamp } from "../utils/timestamp.js";
import userPhotoURL1 from "./../assets/image/chat_bot_1.jpg";
import "./Chatbot.css";
import ChatbotButton from "./ChatBotButton.js";
import EndChatPrompt from "./EndChatPrompt.js";
import ErrorText from "./ErrorText.js";

const NewChatbot = ({ setIsChatOpen }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [lastMessage, setLastMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [askEndConversation, setAskEndConversation] = useState(false);
  const [firstMessageSent, setFirstMessageSent] = useState(true);
  const [aiAnswer, setAiAnswer] = useState("");
  const [conversationIsNotDone, setConversationIsNotDone] = useState(true);


  const bots = [
    { agentId: "88", agentName: "Jai Pereira", agentPhoto: userPhotoURL1 },
  ];

  const randomIndex = Math.floor(Math.random() * bots.length);
  const selectedBot = bots[randomIndex];

  const { currentUser } = useAuth();

  const [conversationId, setConversationId] = useState(null);
  const [conversationIdHoldOn, setConversationIdHoldOn] = useState(false);
  const [selectedLanguages, setSelectedLanguages] = useState("ms_MY");
  const [sendButtonLoading, setSendButtonLoading] = useState(false);

  const [isFullScreen, setIsFullScreen] = useState(false);

  const chatBodyRef = useRef(null);

  const {
    loading,
    error,
    response,
    newChatPrompt,
    saveConversation,
  } = useApiRequest();

  const { sendMessageWebSocket, broadcastMessage } = useWebSocket(
    WEBSOCKET_SERVER
  );

  const chatRef = useRef(null);

  const handleFullscreen = () => {
    if (chatRef.current) {
      if (!isFullScreen) {
        // Enter fullscreen
        if (chatRef.current.requestFullscreen) {
          chatRef.current.requestFullscreen();
        } else if (chatRef.current.webkitRequestFullscreen) {
          chatRef.current.webkitRequestFullscreen();
        } else if (chatRef.current.msRequestFullscreen) {
          chatRef.current.msRequestFullscreen();
        }
      } else {
        // Exit fullscreen
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
          document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      }
    }
  };

  // Listen for fullscreen changes to update the state
  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement); // Check if any element is fullscreen
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
      document.removeEventListener("mozfullscreenchange", handleFullscreenChange);
      document.removeEventListener("MSFullscreenChange", handleFullscreenChange);
    };
  }, []);

  useEffect(() => {
    setIsChatOpen(isOpen);
  }, [isOpen, setIsChatOpen]);

  // Toggle the chatbot open or closed
  const toggleChatbot = () => {
    setIsOpen((prev) => !prev);
  };

  useEffect(() => {
    if (broadcastMessage) {
      const messageBroad = JSON.parse(broadcastMessage);

      if (messageBroad.conversationID !== conversationId) {
        return;
      }

      console.log("message received websocket:", messageBroad);
      console.log("'type' in messageBroad:", "type" in messageBroad);

      // Check if the message from Admin or Bot
      if ("type" in messageBroad) {
        const { status } = messageBroad;
        console.log("status websocket:", messageBroad);
        if (status === "pending") {
          setConversationIdHoldOn(false);
        } else if (status === "HOLD ON") {
          setConversationIdHoldOn(true);
        }
      } else {
        setMessages((prevMessages) => [...prevMessages, messageBroad]);
      }
    }
  }, [broadcastMessage]);

  useEffect(() => {
    setIsTyping(false);
  }, [error]);

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };

  // Send the first message when the chatbot is opened for the first time
  useEffect(() => {
    if (isOpen && firstMessageSent) {
      setIsTyping(true);
      const newConversationId = uuidv4();
      setConversationId(newConversationId);
    }
  }, [isOpen, firstMessageSent]);

  const setWelcomeMessage = () => {
    if (messages.length === 1) {
      setTimeout(() => {
        const welcomeMessage = new MessageWebsocket({
          text: getLocalizedMessage(),
          sender: SENDER_BOT,
          conversationId,
          bot_id: selectedBot.agentId,
          user: currentUser.user,
          language: selectedLanguages,
          timestamp: generateTimestamp(),
        });

        welcomeMessage.validate();
        sendMessageWebSocket(welcomeMessage.toJSON());

        setMessages([welcomeMessage]);
        setIsTyping(false);
        setFirstMessageSent(false);
      }, 100);
    }
  };

  const getLocalizedMessage = () => {

    if (selectedLanguages === ("en")) {
      return `Dear ${capitalizeFirstLetter(currentUser.user)},\n\nHi! How can I assist you today?`
    }

    if (selectedLanguages === ("ms_MY")) {
      return `Pemain yang dihormati ${capitalizeFirstLetter(
        currentUser.user
      )},\n\nHai! Bagaimana saya boleh membantu anda hari ini?`
    }

    if (selectedLanguages.includes("zh_CN")) {
      return `亲爱的 ${capitalizeFirstLetter(currentUser.user)}，\n\n您好！今天我能为您提供什么帮助呢？`
    }

    if (selectedLanguages.includes("zh_TW")) {
      return `親愛的 ${capitalizeFirstLetter(currentUser.user)}，\n\n您好！今天我能為您提供什麼幫助呢？`
    }
  };

  useEffect(() => {
    if (messages.length === 1) {
      const welcomeMessage = {
        text: getLocalizedMessage(),
        sender: SENDER_BOT,
        conversationId,
        bot_id: selectedBot.agentId,
        user: currentUser.user,
        language: selectedLanguages,
        timestamp: generateTimestamp(),
      };

      setMessages([welcomeMessage]);
    }
  }, [selectedLanguages]);

  useEffect(() => {
    if (conversationId) {
      setTimeout(() => {
        const welcomeMessage = new MessageWebsocket({
          text: getLocalizedMessage(),
          sender: SENDER_BOT,
          conversationId,
          bot_id: selectedBot.agentId,
          user: currentUser.user,
          language: selectedLanguages,
          timestamp: generateTimestamp(),
        });

        welcomeMessage.validate();
        //sendMessageWebSocket(welcomeMessage.toJSON());

        setMessages([welcomeMessage]);
        setIsTyping(false);
        //setFirstMessageSent(true);
      }, 100);
    }
  }, [conversationId]);



  useEffect(() => {
    if (response === null) {
      return;
    }

    setIsTyping(false);

    if (conversationIdHoldOn.length === 0) {
      return;
    }

    if (response.conversation_id !== conversationId) {
      return;
    }

    if (response) {
      console.log("Response from API:", response);
      setAiAnswer(response);

      const formattedText = response.generation.split("\n").map((line) => (
        <React.Fragment key={uuidv4()}>
          {line}
          <br />
        </React.Fragment>
      ));

      const combinedAdminText = response.generation;

      triggerBotResponse(formattedText, combinedAdminText);
    }
  }, [response]);

  // Handle sending a message
  const handleSendMessage = async () => {
    if (loading) return;


    if (firstMessageSent) {
      setWelcomeMessage();
    }
    if (message.trim()) {
      setSendButtonLoading(true);

      const newMessage = new MessageWebsocket({
        text: message,
        sender: SENDER_USER,
        conversationId,
        bot_id: selectedBot.agentId,
        user: currentUser.user,
        user_id: currentUser.use,
        language: selectedLanguages,
        timestamp: generateTimestamp(),
      });

      newMessage.validate();

      setLastMessage(message);

      setTimeout(() => {
        setIsTyping(true);
      }, 4500);
      setTimeout(() => {
        scrollToBottom();
      }, 4600);


      setTimeout(() => {
        sendMessageWebSocket(newMessage.toJSON());
      }, 300);

      setTimeout(() => {
        setMessages((prevMessages) => [...prevMessages, newMessage]);
        setTimeout(() => {
          setSendButtonLoading(false);
        }, 1000);
      }, 1000);

      await newChatPrompt(
        message,
        currentUser.userId,
        conversationId,
        "agent-1",
        "admin-2",
        selectedLanguages
      );

      setMessage("");
    }
  };

  const triggerBotResponse = (translatedText, combinedText) => {
    const typingDelay = Math.max(500, translatedText.length * 100);

    setTimeout(() => {
      if (!conversationIdHoldOn) {
        const botMessage = {
          text: translatedText,
          sender: SENDER_BOT,
          conversationId,
          user: selectedBot.agentName,
          bot_id: selectedBot.agentId,
          timestamp: generateTimestamp(),
        };

        console.log("Bot Message:", botMessage);

        setMessages((prevMessages) => [...prevMessages, botMessage]);

        if (response.is_last_message === "true") {
          setAskEndConversation(true)
        }
        else {
          setAskEndConversation(false)
        }
      }
      const botEnglishMessage = {
        text: combinedText,
        sender: SENDER_BOT,
        conversationId,
        user: selectedBot.agentName,
        bot_id: selectedBot.agentId,
        timestamp: generateTimestamp(),
      };

      sendMessageWebSocket(JSON.stringify(botEnglishMessage));

    }, typingDelay); // Delay response to simulate typing
  };

  // Handle language button toggle
  const handleLanguageButton = (language) => {
    if (messages.length < 2) {
      setSelectedLanguages(language);
    }
  };

  function formatJSON(messages) {
    if (!Array.isArray(messages)) {
      console.error("Error: messages is not an array");
      return {};
    }

    const conversation_id =
      messages[0]?.conversationId || messages[0]?.conversationID;

    const processedMessages = messages.map(
      ({ conversationId, conversationID, text, ...rest }) => {
        const processedText = Array.isArray(text)
          ? text.map((item) =>
            typeof item === "object"
              ? item.props?.children?.join("") || ""
              : item
          )
          : text;

        return {
          text: processedText,
          ...rest,
        };
      }
    );

    return {
      conversation_id,
      messages: processedMessages,
    };
  }

  const handleLogout = () => {
    // Passe `messages` diretamente para formatJSON
    const formattedJSON = formatJSON(messages);

    // Exibir o JSON formatado
    console.log("Final JSON:", JSON.stringify(formattedJSON, null, 2));

    //saveConversation(JSON.stringify(formattedJSON, null, 2));

    setTimeout(() => {
      setConversationIsNotDone(false);
    }, 1000);

    sendMessageWebSocket(
      JSON.stringify({
        type: "status_disconnect",
        conversationID: conversationId,
        status: STATUS_DISCONNECTED,
        timestamp: generateTimestamp(),
      })
    );
  };


  const handleEndChat = () => {
    console.log("Chat encerrado!");
    handleLogout();
    setAskEndConversation(false);
  };

  const handleCancelEndChat = () => {
    console.log("Encerramento cancelado!");
    setAskEndConversation(false);
  };

  return (
    <div className="chatbot" >
      <ChatbotButton toggleChatbot={toggleChatbot} />

      <AnimatePresence>
        {isOpen && (
          <motion.div
            ref={chatRef}
            className="chatbot-window"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 20 }}
            transition={{ duration: 0.3 }}
          >
            <div className="chatbot-header" >
              <img
                src={selectedBot.agentPhoto}
                alt={`${selectedBot.agentName}'s avatar`}
                className="chatbot-avatar"
              />
              <h4>{selectedBot.agentName}</h4>
              <div className="header-buttons">
                <button onClick={handleLogout} className="logout-button">
                  <HiOutlineLogout className="logout-icon" />
                </button>
                <button onClick={toggleChatbot} className="close-button">
                  <span className="minimize-button">›</span>
                </button>
                <button onClick={handleFullscreen} className="expand-button">
                  {isFullScreen ?
                    <HiOutlineX className="maximize-button" />
                    : <HiOutlineArrowsExpand className="maximize-button" />}
                </button>
              </div>
            </div>
            {
              <div className="language-holder">
                <button
                  onClick={() => handleLanguageButton("ms_MY")}
                  className={
                    selectedLanguages === ("ms_MY") ? "selected" : ""
                  }
                >
                  MYS
                </button>
                <button
                  onClick={() => handleLanguageButton("zh_CN")}
                  className={selectedLanguages.includes("zh_CN") ? "selected" : ""}
                >
                  简体
                </button>
                <button
                  onClick={() => handleLanguageButton("zh_TW")}
                  className={selectedLanguages.includes("zh_TW") ? "selected" : ""}
                >
                  繁體
                </button>
                <button
                  onClick={() => handleLanguageButton("en")}
                  className={selectedLanguages === ("en") ? "selected" : ""}
                >
                  ENG
                </button>
              </div>
            }

            <div className="chatbot-body" ref={chatBodyRef}>
              {messages.map((msg, index) => (
                <div key={message.id || index}>
                  <div
                    key={index}
                    className={`message-container ${msg.sender}`}
                  >
                    <p
                      className={`message ${msg.sender} animate fader ${msg.feedback === "liked"
                        ? "liked"
                        : msg.feedback === "disliked"
                          ? "disliked"
                          : ""
                        }`}
                    >
                      {Array.isArray(msg) ? msg.join(' ') : msg.text}
                    </p>
                  </div>
                </div>
              ))}

              {askEndConversation && (
                <EndChatPrompt
                  onConfirmEnd={handleEndChat}
                  onCancelEnd={handleCancelEndChat}
                />
              )}


              {error && <ErrorText>{error}</ErrorText>}

              {isTyping && (
                <div className="typing-indicator ">
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              )}
            </div>

            {conversationIsNotDone && <div className="chatbot-input">
              <textarea
                value={message}
                rows={1}
                className="chatbot-textarea"
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Type Here…"
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    if (e.nativeEvent.isComposing) {
                      // Ignore Enter when IME composition is active
                      return;
                    }

                    if (!e.shiftKey) {
                      e.preventDefault(); // Prevent default Enter behavior
                      setMessage((prevMessage) => prevMessage + "\n"); // Add a newline to the message
                    }
                  }
                }}
              />
              {sendButtonLoading ? (
                <span className="chatbot-sending">
                  <div className="loading-spinner"></div>
                </span>
              ) : (
                <button
                  onClick={() => {
                    handleSendMessage(message);
                    setMessage("");
                  }}
                >
                  <span className="button-chev">›</span>
                </button>
              )}
            </div>}


          </motion.div>
        )}
      </AnimatePresence>
    </div >
  );
};

NewChatbot.propTypes = {
  setIsChatOpen: PropTypes.func.isRequired,
};

export default NewChatbot;
