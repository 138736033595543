import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";

import AskAI from "./page/AskAI.js";
import CollectAnswer from "./page/CollectAnswer.js";
import NewMainChatbot from "./page/NewMainChatbot.js";
import VersionList from "./page/VersionList.js";

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="/collect_answer" element={<CollectAnswer />} />
          <Route path="/quality_control" element={<AskAI />} />
          <Route path="/versions" element={<VersionList />} />

          {/* 404 Not Found page for unmatched routes */}
          <Route path="*" element={<NewMainChatbot />} />
        </Routes>
      </Router>
    </div>
  );
}
export default App;
